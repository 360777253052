
<template>
  <div class="login">
    <BaseHeaderBar :title="title" :showCustomerService="false" :showBackButton="true" color="#4837BC" bgColor="transparent" />
    <form class="login-content" @submit.prevent="onSubmit">
      <div class="input-block">
        <img src="@/assets/img/phone-icon.png" />
        <input v-model.trim="phone" type="tel" maxlength="13" autofocus placeholder="Masukkan nomor handphone dengan awalan 08">
      </div>
      <div v-if="showPasswordInput" class="input-block">
        <img src="@/assets/img/password-icon.png" />
        <input v-model.trim="password" maxlength="8" type="tel" class="password" :placeholder="tipText">
      </div>
      <div class="input-block">
        <img src="@/assets/img/verify-code-icon.png" />
        <input v-model.trim="verifyCode" type="tel" maxlength="4" placeholder="Masukkan 4 digit angka kode verifikasi">
        <div class="verify-code-btn" v-if="isGetCode" @click="getVerifyCode">Dapatkan kode verifikasi</div>
        <div class="verify-code-btn count-down" v-else>{{ countDown }}s</div>
      </div>
      <button type="submit" :class="['confirm-btn', showPasswordInput ? 'near' : 'far' ]">{{ buttonText }}</button>
    </form>

    <van-dialog v-model="visible" :showConfirmButton="false" close-on-click-overlay class="verify-img-dialog">
      <h3 class="verify-title">Diperlukan verifikasi</h3>
      <div class="verify-body">
        <div class="verify-img-block">
          <div class="verify-img">
            <img ref="verifyImg" />
          </div>
          <div class="refresh-btn" @click="refreshVerifyCode">
            <img src="@/assets/img/refresh-icon.png" alt="refresh" />
          </div>
        </div>
        <div class="verify-input-box">
          <div v-for="(item, index) in verifyImgCodes" :key="index">
            <input :ref="`input${index}`" v-model="item.value" type="tel" @input="onInput(index)" @keyup.delete="onDelete(index)" maxlength="1" />
          </div>
          <p class="error-info">{{ errorInfo }}</p>
        </div>
        <button type="button" class="verify-confirm-btn" @click="onVerify">Yakin</button>
      </div>
      <img class="close-icon" src="@/assets/img/verify-close-icon.png" alt="close" @click="onCloseDialog()">
    </van-dialog>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import Vue from 'vue'
import { Toast, Dialog } from 'vant'
import request from '@/utils/request'
import { validatePhoneNumber, validatePassword, validateVerifyCode } from '@/utils/validator'
import dialogVisible from '@/mixins/dialogVisible'
import { baseURL } from '@/config'

Vue.use(Toast)
const maxTime = 150
let timer
const clearTimer = () => {
  timer && clearTimeout(timer) && (timer = null)
}
const titleMap = {
  forgetPassword: 'Lupa Password',
  messageLogin: 'Login dengan SMS',
  register: 'Daftar'
}
const buttonText = {
  forgetPassword: 'Yakin',
  messageLogin: 'Login',
  register: 'Daftar'
}
const apiMap = {
  forgetPassword: 'usdrerForgetPassword',
  messageLogin: 'usdrerLogin',
  register: 'usdrerRegistration'
}

const messageTypeMap = {
  forgetPassword: 3,
  messageLogin: 2,
  register: 1
}

export default {
  name: 'LoginForm',
  props: {
    type: {
      type: String,
      required: true
    }
  },
  mixins: [dialogVisible],
  data () {
    return {
      phone: '',
      password: '',
      verifyCode: '',
      isGetCode: true,
      countDown: maxTime,
      verifyImg: '',
      verifyImgCodes: [
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        },
        {
          value: ''
        }
      ],
      errorInfo: ''
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  computed: {
    title () {
      return titleMap[this.type]
    },
    buttonText () {
      return buttonText[this.type]
    },
    showPasswordInput () {
      return this.type === 'forgetPassword' || this.type === 'register'
    },
    tipText () {
      if (this.type === 'register') {
        return 'Atur 8 digit password'
      } else {
        return 'Masukkan 8 digit angka password'
      }
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.$nextTick(() => {
          this.getImgVerifyCode()
          this.$refs.input0[0].focus()
        })
      }
    }
  },
  methods: {
    validatePhoneNumber () {
      if (!this.phone) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!validatePhoneNumber(this.phone)) {
        Toast('Nomor handphone salah')
        return false
      }
      return true
    },
    validate () {
      if (!this.validatePhoneNumber()) return false
      if (this.showPasswordInput) {
        if (!this.password) {
          Toast(this.tipText)
          return false
        }
        if (!validatePassword(this.password)) {
          Toast('Password salah')
          return false
        }
      }
      if (!this.verifyCode) {
        Toast('Masukkan 4 digit angka kode verifikasi')
        return false
      }
      if (!validateVerifyCode(this.verifyCode)) {
        Toast('Kode verifikasi salah')
        return false
      }
      return true
    },
    startCountDown () {
      if (this.countDown <= 0) {
        this.reset()
      } else {
        timer = setTimeout(() => {
          this.countDown--
          this.startCountDown()
        }, 1000)
      }
    },
    getVerifyCode () {
      if (!this.validatePhoneNumber()) return
      const postData = {
        usdrerPhone: this.phone,
        tydrpe: messageTypeMap[this.type],
        smdrsType: 1
      }
      this.imgVerifyCode && (postData.imdrgCode = this.imgVerifyCode)
      this.countDown = maxTime
      this.isGetCode = false
      this.startCountDown()
      request.post('usdrersendMessages', postData)
        .then(() => {
          Toast('Kode verifikasi terkirim. Silakan cek')
        })
        .catch(err => {
          if (err.code === 504) {
            this.visible = true
          }
          this.reset()
        })
    },
    getImgVerifyCode () {
      this.$refs.verifyImg.src = `${baseURL}usdrergetGraphic?usdrerPhone=${this.phone}&t=${+Date.now()}`
    },
    reset () {
      clearTimer()
      this.isGetCode = true
      this.countDown = maxTime
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        usdrerPhone: this.phone,
        smdrs_Verificationcode: this.verifyCode,
        smdrsType: 1
      }
      if (this.type === 'forgetPassword') {
        postData.usdrerPassword = this.password
      } else if (this.type === 'messageLogin') {
        postData.lodrginType = 2
      } else if (this.type === 'register') {
        postData.usdrerPassword = this.password
        postData.prdromotionChannel = 'AppStore'
      }
      request.post(apiMap[this.type], postData)
        .then(res => {
          if (this.showPasswordInput) {
            const { query } = this.$route
            this.$router.push({
              path: '/login',
              query
            })
          } else if (this.type === 'messageLogin') {
            this.$store.commit('recordInfo', {
              type: 'userInfo',
              value: res
            })
            Cookies.set('userInfo', JSON.stringify(res))
            const { redirectUrl = '/' } = this.$route.query
            this.$router.push(redirectUrl)
          }
        })
        .finally(() => {
          this.flag = false
        })
    },
    onVerify () {
      this.imgVerifyCode = ''
      this.verifyImgCodes.forEach(item => {
        this.imgVerifyCode = this.imgVerifyCode + item.value
      })
      const postData = {
        usdrerPhone: this.phone,
        grdraphicCode: this.imgVerifyCode
      }
      request.post('usdrercheckGraphic', postData)
        .then(res => {
          Toast('Verifikasi Berhasil')
          this.visible = false
        }).catch(() => {
          this.getImgVerifyCode()
        })
    },
    refreshVerifyCode () {
      this.getImgVerifyCode()
    },
    onInput (index) {
      if (this.verifyImgCodes[index].value && index < 3) {
        console.log('onInput', index)
        this.$refs[`input${index + 1}`][0].focus()
      }
    },
    onDelete (index) {
      if (index > 0) {
        this.$refs[`input${index - 1}`][0].focus()
        console.log('onDelete', index)
      }
    }
  },
  deactivated () {
    this.reset()
  }
}
</script>

<style scoped lang="less">
.login {
  min-height: 100vh;
  background: url(../assets/img/forget-password-bg.jpg) no-repeat;
  background-size: cover;
}
.login-content {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 167px;
}

.input-block {
  position: relative;
  display: flex;
  align-items: center;
  width: 570px;
  border-bottom: 2px solid rgba(72,55,188,.21);

  &:nth-of-type(n+2) {
    margin-top: 55px;
  }

  &:nth-of-type(-n+2) img {
    width: 28px;
    height: 34px;
  }

  &:last-of-type img {
    width: 26px;
    height: 31px;
  }

  input {
    flex: 1;
    height: 100px;
    line-height: 100px;
    border: none;
    padding-left: 20px;
    font-size: 30px;
    color: #333;
    background-color: transparent;

    &::placeholder {
      color: #737B91;
      font-size: 24px;
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  .password {
    -webkit-text-security: disc;
  }

  .verify-code-btn {
    position: absolute;
    left: 464px;
    width: 256px;
    height: 88px;
    padding-top: 6px;
    border-radius: 44px;
    box-sizing: border-box;
    font-size: 28px;
    font-weight: 600;
    color: #FFF;
    text-align: center;
    background-image: linear-gradient(239deg,#B83AF3 0%,#6950FB 100%);
  }

  .count-down {
    line-height: 88px;
  }
}

.confirm-btn {
  width: 100%;
  height: 120px;
  line-height: 120px;
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.1);
  border-radius: 138px;
  box-sizing: border-box;
  font-size: 32px;
  font-weight: 600;
  color: #FFF;
  text-align: center;
  background: linear-gradient(315deg,#938BD9 0%,#F2F2F2 100%);

  &.near {
    margin-top: 142px;
  }

  &.far {
    margin-top: 301px;
  }
}

.verify-img-dialog {
  background: url(../assets/img/verify-img-dialog-bg.png) no-repeat;
  background-size: cover;
}

.verify-title {
  line-height: 42px;
  padding-top: 49px;
  font-size: 30px;
  color: #FFF;
  text-align: center;
}

.verify-body {
  margin-left: 72px;
  margin-right: 72px;
}

.verify-img-block {
  display: flex;
  align-items: center;
  margin-top: 68px;
  height: 144px;
}

.verify-img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 2px solid #D9D5F1;
  border-right: none;
  border-radius: 8px 0 0 8px;
  box-sizing: border-box;

  img {
    height: 100%;
  }
}

.refresh-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 78px;
  height: 100%;
  background-image: linear-gradient(239deg,#B83AF3 0%,#6950FB 100%);
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;

  img {
    width: 40px;
    height: 40px;
  }
}

.verify-input-box {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;

  div {
    flex: 1;

    &:nth-child(n+2) {
      margin-left: 64px;
    }
  }

  input {
    width: 100%;
    line-height: 88px;
    padding: 0;
    border: none;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 30px;
    color: #333;
    text-align: center;
    background-color: #FFF;
  }

  .error-info {
    margin-top: 8px;
    font-size: 24px;
    color: #FFF;
  }
}

.verify-confirm-btn {
  width: 546px;
  height: 88px;
  line-height: 88px;
  border-radius: 44px;
  margin-top: 32px;
  margin-bottom: 54px;
  font-size: 32px;
  font-weight: 600;
  color: #FFF;
  background-image: linear-gradient(239deg,#B83AF3 0%,#6950FB 100%);
}

.close-icon {
  position: absolute;
  top: 25px;
  right: 24px;
  width: 78px;
  height: 78px;
}
</style>
